/**
 * Clever Canary
 * https://clevercanary.com/
 *
 * GA dimensions (entity name and entity type only) keyed by href, used in GA/GTM event configuration.  
 */

// App dependencies
import { GAEntityName } from "./ga-entity-name.model";
import { GAEntityType } from "./ga-entity-type.model";

export const GADimensionsByHref = {

    // Home - Hero - Mail To
    "mailto:hello@clevercanary.com": {
        entityName: GAEntityName.CLEVER_CANARY,
        entityType: GAEntityType.CONTACT
    },

    // Our Work - Anvil Portal
    "https://anvilproject.org": {
        entityName: GAEntityName.ANVIL,
        entityType: GAEntityType.PROJECT
    },

    // Our Work - Anvil Portal GitHub
    "https://github.com/anvilproject/anvil-portal": {
        entityName: GAEntityName.ANVIL,
        entityType: GAEntityType.REPO
    },

    // Our Work - Data Browser
    "https://data.humancellatlas.org/explore": {
        entityName: GAEntityName.HCA_DATA_BROWSER,
        entityType: GAEntityType.PROJECT
    },

    // Our Work - Data Browser GitHub
    "https://github.com/HumanCellAtlas/data-browser": {
        entityName: GAEntityName.HCA_DATA_BROWSER,
        entityType: GAEntityType.REPO
    },

    // Our Work - Data Portal
    "https://data.humancellatlas.org/": {
        entityName: GAEntityName.HCA_DATA_PORTAL,
        entityType: GAEntityType.PROJECT
    },

    // Our Work - Data Portal GitHub
    "https://github.com/HumanCellAtlas/data-portal": {
        entityName: GAEntityName.HCA_DATA_PORTAL,
        entityType: GAEntityType.REPO
    },

    // Our Work - UCSC Xena Browser
    "https://xenabrowser.net/": {
        entityName: GAEntityName.UCSC_XENA_BROWSER,
        entityType: GAEntityType.PROJECT
    },

    // Our Work - UCSC Xena Browser GitHub
    "https://github.com/ucscXena/ucsc-xena-client": {
        entityName: GAEntityName.UCSC_XENA_BROWSER,
        entityType: GAEntityType.REPO
    },

    // Our Work - UCSC Xena Portal
    "https://xena.ucsc.edu/": {
        entityName: GAEntityName.UCSC_XENA_PORTAL,
        entityType: GAEntityType.PROJECT
    },

    // Our Work - UCSC Xena Portal GitHub
    "https://github.com/ucscXena/xena-web": {
        entityName: GAEntityName.UCSC_XENA_PORTAL,
        entityType: GAEntityType.REPO
    },

    // Footer - Twitter
    "https://twitter.com/clevercanary": {
        entityName: GAEntityName.TWITTER,
        entityType: GAEntityType.SOCIAL_MEDIA
    },

    // Our Work - Xena Paper
    "https://doi.org/10.1038/s41587-020-0546-8": {
        entityName: GAEntityName.UCSC_XENA_NATBIO_2020,
        entityType: GAEntityType.PAPER
    },
    // Our Work - Xena Paper Preprint
    "https://www.biorxiv.org/content/10.1101/326470v6": {
        entityName: GAEntityName.UCSC_XENA_BIORXIV_2020,
        entityType: GAEntityType.PREPRINT
    }
};



