/*
 * Clever Canary
 * https://clevercanary.com/
 *
 * Clever Canary header component.
 */

// Core dependencies
import React from "react";

// Images
import ccLogo from "../../../images/logo/logo-clevercanary.png"

// Styles
import compStyles from "./header.module.css"

class Header extends React.Component {

    render() {
        return (
            <div className={compStyles.header}>
                <div className={compStyles.navBar}>
                    <a href="/" className={compStyles.logo}><img src={ccLogo} alt="cc"/></a>
                </div>
            </div>
        )
    }
}

export default Header
