/*
 * Clever Canary
 * https://clevercanary.com/
 *
 * Clever Canary layout component.
 */

// Core dependencies
import React from "react";

// App dependencies
import Footer from "./footer/footer";
import Header from "./header/header";
import PageHead from "./page-head/page-head";
import SEO from "./seo/seo";
import * as CCGTMService from "../utils/cc-gtm/cc-gtm.service";
import * as DOMService from "../utils/dom.service";

// Styles
import compStyles from "./layout.module.css";

require(`katex/dist/katex.min.css`);
require(`prismjs/themes/prism-solarizedlight.css`);

class Layout extends React.Component {

    /**
     * Set banner height and scroll defaults. Create ref for handling tracking of external links.
     */
    constructor(props) {

        super(props);
        this.containerEl = React.createRef();
    }

    /**
     * Set up tracking of external links.
     */
    componentDidMount() {

        this.containerEl.current.addEventListener("click", this.onClick, {passive: true});
    }

    onClick = (e) => {

        const target = e.target;
        const parent = target.parentNode;

        if ( !DOMService.isAnchor(target) && !DOMService.isAnchor(parent) ) {
            return;
        }

        const url = target.getAttribute("href") || parent.getAttribute("href");
        if ( DOMService.isHrefExternal(url) || DOMService.isMailTo(url) ) {

            const linkText = target.innerText;
            CCGTMService.trackExternalLinkClicked(url, linkText);

        }
    };

    render() {
        const {children, description, title} = this.props,
            site = "Clever Canary";
        return (
            <div ref={this.containerEl}>
                <PageHead pageTitle={title} site={site}/>
                <SEO description={description} site={site} title={title}/>
                <div className={compStyles.site}>
                    <Header/>
                    {children}
                    <Footer/>
                </div>
            </div>
        )
    }
}

export default Layout;
