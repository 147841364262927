/**
 * Clever Canary
 * https://clevercanary.com/
 *
 * Set of urls for the "current" environment.
 */

export const EnvironmentUrl = {
    "LOCAL": "http://localhost:8000/",
    "MASTER": "https://clevercanary.com/",
    "STAGING": "https://staging.clevercanary.com/"
};
