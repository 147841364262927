/*
 * Clever Canary
 * https://clevercanary.com/
 *
 * Clever Canary footer component.
 */

// Core dependencies
import { Link } from "gatsby";
import React from "react";

// Styles
import compStyles from "./footer.module.css";
import globalStyles from "../../styles/global.module.css";

let classNames = require('classnames');

const Footer = () => (
    <>
        <footer data-cc-event="Contact us" className={globalStyles.bgBlack}>
            <div className={globalStyles.wrapper}>
                <h2>Contact us.</h2>
                <ul>
                    <li className={compStyles.email}><a title="Email us: hello at clever canary dot com" href="mailto:hello@clevercanary.com">hello@clevercanary.com</a></li>
                    <li className={compStyles.location}><address>Santa Cruz, CA</address></li>
                    <li className={compStyles.twitter}><a target="_blank" rel="noopener noreferrer" title="Clever Canary Twitter" href="https://twitter.com/clevercanary">@clevercanary</a></li>
                </ul>
            </div>
        </footer>
        <div className={globalStyles.bgBlack}>
            <div className={classNames(globalStyles.wrapper, compStyles.privacy)}>
                <div>
                    <Link to="/privacy">Privacy</Link>
                </div>
            </div>
        </div>
    </>
);

export default Footer
