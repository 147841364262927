/**
 * Clever Canary
 * https://clevercanary.com/
 *
 * Set of possible values for the "entity name" GA dimension.
 */

export const GAEntityName = {
    "ANVIL": "AnVIL",
    "CLEVER_CANARY": "Clever Canary",
    "TWITTER": "Twitter",
    "HCA_DATA_BROWSER": "HCA Data Browser",
    "HCA_DATA_PORTAL": "HCA Data Portal",
    "UCSC_XENA_BROWSER": "UCSC Xena Browser",
    "UCSC_XENA_PORTAL": "UCSC Xena Portal",
    "UCSC_XENA_NATBIO_2020": "Visualizing and interpreting cancer genomics data via the Xena platform",
    "UCSC_XENA_BIORXIV_2020": "The UCSC Xena platform for public and private cancer genomics data visualization and interpretation",
    "UNSPECIFIED": "Unspecified",
};
