/**
 * Clever Canary
 * https://clevercanary.com/
 *
 * Possible values of Google Analytic actions.
 */

export const GAAction = {
    "CLICK": "Click"
};
