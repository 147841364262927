/*
 * Clever Canary
 * https://clevercanary.com/
 *
 * Clever Canary page head component.
 */

// Core dependencies
import React from "react";
import Helmet from "react-helmet";

// App dependencies
import * as EnvironmentService from "../../utils/environment/environment.service";

class PageHead extends React.Component {

    render() {
        const {pageTitle, site} = this.props,
            title = pageTitle ? `${pageTitle} | ${site}` : site;
        return (
            <Helmet>
                <title>{title}</title>
                {EnvironmentService.isProd() ? null : <meta name="robots" content="noindex" />}
                <meta name="Clever Canary" content="Clever Canary"/>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>
                <link rel="stylesheet" type="text/css" href="//cloud.typography.com/7635272/7454412/css/fonts.css"/>
                <html lang="en" />
            </Helmet>
        )
    }
}

export default PageHead;
