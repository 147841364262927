/**
 * Clever Canary
 * https://clevercanary.com/
 *
 * Possible values of Google Analytic categories.
 */

export const GACategory = {
    "EXTERNAL_LINK": "External Link"
};
