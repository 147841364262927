/*
 * Clever Canary
 * https://clevercanary.com/
 *
 * Clever Canary seo component.
 */

// Core dependencies
import React from "react";
import Helmet from "react-helmet";

// App dependencies
import * as EnvironmentService from "../../utils/environment/environment.service";

class SEO extends React.Component {

    render() {
        const {description, site, title} = this.props,
            siteURL = EnvironmentService.getCurrentEnvironmentURL(),
            twitterImgUrl = `${siteURL}images/twitter-clevercanary.png`;
        return (
            <Helmet>
                <meta property="og:title" content={title}/>
                <meta property="og:site_name" content={site}/>
                <meta property="twitter:title" content={title}/>
                {description ? [
                    <meta property="twitter:description" content={description} key="twitter:description"/>,
                    <meta name="description" content={description} key="description" />,
                    <meta property="og:description" content={description} key="og:description" />,
                    <meta name="twitter:image" content={twitterImgUrl} key="twitter:image"/>
                ] : null}
                <meta name="twitter:card" content="summary"/>
            </Helmet>
        )
    }
}

export default SEO;
