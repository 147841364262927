/**
 * Clever Canary
 * https://clevercanary.com/
 *
 * Set of possible values for the "entity type" GA dimension.
 */

export const GAEntityType = {
    "CONTACT": "Contact",
    "PAPER": "Paper",
    "PREPRINT": "Preprint",
    "PROJECT": "Project",
    "REPO": "Repo",
    "SOCIAL_MEDIA": "Social Media",
    "UNSPECIFIED": "Unspecified"
};
